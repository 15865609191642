/**
 * Check if the provided URL is a relative URL.
 * Returns true if it is relative.
 * @param {string} url - The URL to be checked
 * @returns {boolean} - True if the provided URL is a relative URL
 */
export const isRelativeUrl = url => url.match(/^https?:\/\//) === null

/**
 * Returns an absolute url
 * This function makes sure to return a valid URL, without double slashes
 * @param {string} origin - The origin (e.g. https://adlexicon.com/)
 * @param {string} path - The path to the object (e.g. /images/example.png)
 * @returns {string} - The combined path and origin (e.g. https://www.adlexicon.com/images/example.png)
 */
export const getAbsoluteUrl = (origin, path) => new URL(path, origin).href
