import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import style from "./header.module.css"

const Header = ({ siteTitle }) => (
  <header className={style.header}>
    <div>
      <h1 style={{ margin: 0, lineHeight: 0, fontSize: `1.2em` }}>
        <Link to="/">{siteTitle}</Link>
      </h1>

      <nav>
        <Link to="/dictionary/" activeStyle={{ textDecoration: `underline` }}>
          Dictionary
        </Link>
        <Link to="/guides/" activeStyle={{ textDecoration: `underline` }}>
          Guides
        </Link>
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
